// User Pages

.app-logo {
  // height: $logo-height;
  // width: $logo-width;
  // background: url('~assets/utils/images/logo-inverse.png');  
  // Left the above here and commented out, b/c we will eventually want to
  // work through these templated variables once we know more about them.
  height: 58px;
  width: 300px;
  background-size: contain; // Keeps aspect ratio
  background-repeat: no-repeat;
  background-position: center;
  background: url('~assets/utils/images/ms-logo-black.png');
}

.app-logo-inverse {
  // height: $logo-height;
  // width: $logo-width;
  // background: url('~assets/utils/images/logo-inverse.png');
  // Left the above here and commented out, b/c we will eventually want to
  // work through these templated variables once we know more about them.
  height: 58px;
  width: 300px;
  background-size: contain; // Keeps aspect ratio
  background-repeat: no-repeat;
  background-position: center;
  background: url('~assets/utils/images/ms-logo-white.png');
}

.app-login-box {
  padding-top: 50px;
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}